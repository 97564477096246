import { createSlice } from '@reduxjs/toolkit'

const initialState = null

export const userSlice = createSlice({
    name: 'token',
    initialState,
    reducers: {
        setToken: (state, { payload }) => payload,
        deleteToken: () => initialState
    }

})
export const { setToken, deleteToken } = userSlice.actions
export default userSlice.reducer